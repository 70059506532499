import React from "react";

const App = () => {
  return(
    <>
      <h1>Hello World</h1>
    </>
  )
}

export default App;